
* { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
.light{
    --prim: #fff;
    --lord: #2d2c39;
    --thron: #ccc;
    --dragon: #000;
}

body {
    scroll-behavior: smooth;
    transition: all 200ms ease-in-out;
    font-size: 15px;
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow: hidden;
}
#root{
    height: 100vh;
    overflow: hidden;
}

.scroller{
    overflow-y: auto;
    max-height: 100%;
}

.scroller::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin: 10px;
}
.scroller::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #3f3c4daa;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
}

.scrller-container {
    overflow-y: auto;
    max-height: 100vh;
} 


.popup{
    position: fixed;
    inset:  0;
    z-index: 100;
    background-color: #3333;
    display: flex;
}
.popup-child{
    margin: auto;
    transition: all 200ms ease-in-out;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px #3339;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
img{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.col-span-fill{
    grid-column: span 4;
}