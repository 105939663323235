@import "root.css";
@import "elements.css"; 
@import "animations.css"; 
@import "theme.css"; 
@import "containers.css"; 
* { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
    scroll-behavior: smooth;
    transition: all 200ms ease-in-out;
    font-size: 15px;
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow: hidden;
}
#root{
    height: 100vh;
    overflow: hidden;
}

#rapper{
  height: 100vh;
  overflow: auto;
}

.light {
   --prim:#FFFFFF;
   --throne: #FBFBFB;
   --lord: #FBFBFB;
   --prince: #F0F0F0;
   --king:#f6f6f6;

   --shark: #8e9a9b;
   --goat: #b9cad4;
   --dolphin: #b1acac;
   --whale: #105588;
   --crow: #150d23; 

   --frog: #bada55;
   --penguin: #1fd1ec; 

   --fox:#d69e58;
   --wolf: #b2a47b;
   --lion: #b59675;
   --tiger: #8d3c09;

   --fox:#f48114;
   --wolf: #ffa44e;
   --lion: #f9c089;
   --tiger: #905929;
   
   --dragon: #dd3643;
   --fire: #ec4245;
   --flamingo: #f29bb9;
   
   --jellyfish: #8a41e4;
   --lavender: #e4b1e4;
   
   --active:rgb(21 94 117);
   --light:rgb(156 163 175);
   
   background-color: #ffffff;
}

.dark {
   --prim:#2d303e;
   --throne: #353848;
   --lord: #292a39;
   --prince: #393c4a;
   --king: #3b3e4c;
   
   --shark: #ffffff;
   --dolphin: #6c6e78;
   --goat: #9e9fa6;
   --crow: #c4c4c7;
   --whale: #767982;
  
   --frog: #bada55;
   --penguin: #1fd1ec;
  
   --fox:#d69e58;
   --wolf: #b2a47b;
   --lion: #b59675;
   --tiger: #8d3c09;
  
   --dragon: #9b2b19;
   --fire: #e9b11f;
   --flamingo: #f29bb9;
   
   --jellyfish: #8a41e4;
   --lavender: #e4b1e4;
  
   --active:rgb(21 94 117);
   --light:rgb(156 163 175);
  
   background-color: #1f1d2b;
}

p[state='selected']{
background-color: var(--penguin);
}
p[state='off']{
background-color: var(--goat);
}

.bg-prim      {background-color: var(--prim);}       .text-prim      {color: var(--prim);}       .fill-shark{fill: var(--shark);}
.bg-throne    {background-color: var(--throne);}     .text-throne    {color: var(--throne);}     
.bg-lord      {background-color: var(--lord);}       .text-lord      {color: var(--lord);}       
.bg-prince    {background-color: var(--prince);}     .text-prince    {color: var(--prince);}     
.bg-king      {background-color: var(--king);}       .text-king      {color: var(--king);}       
.bg-shark     {background-color: var(--shark);}      .text-shark     {color: var(--shark);}      
.bg-dolphin   {background-color: var(--dolphin);}    .text-dolphin   {color: var(--dolphin);}    
.bg-goat      {background-color: var(--goat);}       .text-goat      {color: var(--goat);}       
.bg-crow      {background-color: var(--crow);}       .text-crow      {color: var(--crow);}       
.bg-whale     {background-color: var(--whale);}      .text-whale     {color: var(--whale);}      
.bg-frog      {background-color: var(--frog);}       .text-frog      {color: var(--frog);}       
.bg-penguin   {background-color: var(--penguin);}    .text-penguin   {color: var(--penguin);}    
.bg-fox       {background-color: var(--fox);}        .text-fox       {color: var(--fox);}        
.bg-wolf      {background-color: var(--wolf);}       .text-wolf      {color: var(--wolf);}       
.bg-lion      {background-color: var(--lion);}       .text-lion      {color: var(--lion);}       
.bg-tiger     {background-color: var(--tiger);}      .text-tiger     {color: var(--tiger);}      
.bg-dragon    {background-color: var(--dragon);}     .text-dragon    {color: var(--dragon);}     
.bg-fire      {background-color: var(--fire);}       .text-fire      {color: var(--fire);}       
.bg-flamingo  {background-color: var(--flamingo);}   .text-flamingo  {color: var(--flamingo);}   
.bg-jellyfish {background-color: var(--jellyfish);}  .text-jellyfish {color: var(--jellyfish);}  
.bg-lavender  {background-color: var(--lavender);}   .text-lavender  {color: var(--lavender);}   
.bg-active    {background-color: var(--active);}     .text-active    {color: var(--active);}     
.bg-light     {background-color: var(--light);}      .text-light     {color: var(--light);}      


/* Theme */
.text-black  {color: rgb(0 0 0);}
.text-white  {color: rgb(255 255 255);}

.text-cyan-50   {color: rgb(236 254 255);}
.text-cyan-100  {color: rgb(207 250 254);}
.text-cyan-200  {color: rgb(165 243 252);}
.text-cyan-300  {color: rgb(103 232 249);}
.text-cyan-400  {color: #22d3ee;}
.text-cyan-500  {color: rgb(6 182 212);}
.text-cyan-600  {color: rgb(8 145 178);}
.text-cyan-700  {color: rgb(14 116 144);}
.text-cyan-800  {color: rgb(21 94 117);}
.text-cyan-900  {color: rgb(22 78 99);}

.text-red-50    {color: rgb(254 242 242);}
.text-red-100   {color: rgb(254 226 226);}
.text-red-200   {color: rgb(254 202 202);}
.text-red-300   {color: rgb(252 165 165);}
.text-red-400   {color: rgb(248 113 113);}
.text-red-500   {color: rgb(239 68 68);}
.text-red-600   {color: rgb(220 38 38);}
.text-red-700   {color: rgb(185 28 28);}
.text-red-800   {color: rgb(153 27 27);}
.text-red-900   {color: rgb(127 29 29);}

.text-gray-50   {color: rgb(249 250 251);}
.text-gray-100  {color: rgb(243 244 246);}
.text-gray-200  {color: rgb(229 231 235);}
.text-gray-300  {color: rgb(209 213 219);}
.text-gray-400  {color: rgb(156 163 175);}
.text-gray-500  {color: rgb(107 114 128);}
.text-gray-600  {color: rgb(75 85 99);}
.text-gray-700  {color: rgb(55 65 81);}
.text-gray-800  {color: rgb(31 41 55);}
.text-gray-900  {color: rgb(17 24 39);}

.text-purple-50   {color: rgb(250 245 255);}
.text-purple-100  {color: rgb(243 232 255);}
.text-purple-200  {color: rgb(233 213 255);}
.text-purple-300  {color: rgb(216 180 254);}
.text-purple-400  {color: rgb(192 132 252);}
.text-purple-500  {color: rgb(168 85 247);}
.text-purple-600  {color: rgb(147 51 234);}
.text-purple-700  {color: rgb(126 34 206);}
.text-purple-800  {color: rgb(107 33 168);}
.text-purple-900  {color: rgb(88 28 135);}


