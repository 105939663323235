.transition-0{ transition: all 0s ease-in-out;}
.transition-1{ transition: all 0.1s ease-in-out;}
.transition-2{ transition: all 0.2s ease-in-out;}
.transition-3{ transition: all 0.3s ease-in-out;}
.transition-4{ transition: all 0.4s ease-in-out;}

.shake {
  animation: shakeFrame 500ms linear !important;
  box-shadow: 0 0 8px #f8717155 !important;
  content: "Must Be Filled" !important;
}
.shake::placeholder {
  color: var(--dragon) !important;
}
@keyframes shakeFrame {
  0%,20%,40%,60%,80% {transform: translateX(0);}
  10%,30%,50%,70%,90% {transform: translateX(-6px);}
}

.blur-bg{
    background-color: #0004;
}

@keyframes searching {
  0% , 100% {
    transform: translate(0px, 0px)
  }
  10% , 90% {
    transform: translate(15px, -10px)
  }
  20% , 80% {
     transform: translate(25px, -35px)
  }
  30% , 70% {
    transform: translate(-15px, 10px)
  }
  40% , 60% {
    transform: translate(-30px, 30px)
  }
  50%  {
    transform: translate(25px, -10px)
  }
}

@keyframes searching2 {
 0% ,100% { transform: translate(5px,2px) }
 25% { transform: translate(72px,25px) }
 50% { transform: translate(25px,52px) }
 75% { transform: translate(-25px,25px) }
}

@keyframes searching3 {
 0%  { transform: rotate(0deg) }
 100% { transform: rotate(-360deg) }
}
@keyframes searching4 {
  0%  { transform: rotate(35deg) }
  70% { transform: rotate(320deg) }
  100% { transform: rotate(395deg) }
}

.searching-icon-container{
   position: fixed;
   left: 0;
   right: 0;
   height: 0; 
   z-index: 999;
  }
  
  .searching-animation{
    animation: searching4 2500ms linear infinite;
    display: flex;
    width: 180px;
    height:0;
    margin-inline: auto;
    margin-top: 150px;
  }
  
  .searching-part-conatiner{
    display: flex;
    align-items: center;
    animation: searching3 2500ms linear infinite;

}

.searching-icon { 
  border: 1px solid rgb(22 78 99);
  box-shadow: inset 0 0 4px rgb(22 78 99);
  border-radius: 170px;
  background-color:#ecfeffdd;
  /* padding: 27px; */
}
.clip-shin{
  /* clip-path: polygon(74% 21%, 87% 35%, 90% 75%, 80% 37%, 69% 26%, 35% 8%); */
  /* clip-path: polygon(33% 3%, 51% 3%, 69% 10%, 81% 19%, 90% 34%, 94% 48%, 94% 71%, 82% 44%, 71% 28%, 55% 16%); */
  /* clip-path: polygon(31% 9%, 46% 7%, 66% 12%, 82% 21%, 91% 36%, 94% 57%, 92% 78%, 85% 58%, 74% 30%, 49% 15%); */
  clip-path: polygon(31% 9%, 46% 7%, 66% 12%, 82% 21%, 91% 36%, 94% 57%, 92% 78%, 85% 58%, 74% 30%, 49% 15%);

  margin: 4px;
  padding: 19px;
  background-color: #fffd;
  transform: rotate(-55deg);
}

.searching-icon-tail{
  /* background-color: #a855f7; */
  height: 7px;
  background-color: rgb(22 78 99);
  width: 23px;
  /* margin-left: 1px; */
  border-radius: 0 6px 6px 0;
}


@keyframes bounce {
    0%, 100% {
     bottom:10px;
     padding-block: 40px;
     animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      padding-block: 38px;
      bottom:0;
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

.animate-bounce {animation : bounce 1s infinite; position: absolute;};


/* Loading Animation */
.lds-ripple {
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #63cfc9;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

