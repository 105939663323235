input[type="number"],input[type="text"],input[type="password"] {
  font-family: "'BoutrosMBCDinkum Medium'";
  padding: 10px;
  outline: none;
  border: none;
  color: var(--shark);
  font-weight: bold;
  border-radius: 0.5rem;
  box-shadow:var(--shadow-lg);
  background-color: var(--prim);
}
input::placeholder {
  color: var(--goat);
  letter-spacing: 1px;
  font-size: 14px;
  z-index: 2;
  font-weight: bold;
  position: relative;
}
input[type="number"]:focus,input[type="text"]:focus{box-shadow: var(--shadow-focus);}
input:read-only{background-color: var(--king);}

.button{
  font-family: "'BoutrosMBCDinkum Medium'";
  background-color: var(--prim);
  color: var(--shark);
  border-radius: 0.5rem;
  padding: 6px 16px;
  /* font-size: 12px; */
  border: none;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 1px;
  box-shadow: var(--shadow-lg);
}

.owl-expand{
  max-height: 1800px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
